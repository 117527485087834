import React, { useState } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
	const BASE_API = 'https://mutual-service.vtl-lab.com'

	const [inputs, setInputs] = useState({
		certHolder: '',
		insurePerson: '',
		sex: '',
		nationality: '',
		period: '',
		compensationAmount: '',
		premium: '',
	})

	const handleChange = (e) => {
		const { name, value } = e.target
		setInputs({
			...inputs,
			[name]: value,
		})
	}

	const download = (link) => {
		const downloadBtn = document.createElement('a')
		downloadBtn.href = link
		downloadBtn.download = 'test.pdf'

		document.body.appendChild(downloadBtn)
		downloadBtn.click()
		document.body.removeChild(downloadBtn)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		fetch('https://mutual-service.vtl-lab.com/pdf/acp', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(inputs),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.url) {
					fetch(`https://mutual-service.vtl-lab.com${data.url}`)
						.then((response) => response.blob())
						.then((blob) => {
							const url = window.URL.createObjectURL(blob)
							const a = document.createElement('a')
							a.style.display = 'none'
							a.href = url
							a.download = 'file.pdf'
							document.body.appendChild(a)
							a.click()
							window.URL.revokeObjectURL(url)
						})
						.catch((error) => console.error('Error downloading file:', error))
				}
				console.log('Success:', data)
			})
			.catch((error) => {
				console.error('Error:', error)
			})
	}

	return (
		<div
			className='App'
			style={{
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
			}}>
			<form
				onSubmit={handleSubmit}
				className='container-sm mt-4'>
				<h3 className='h3 my-3'>Mutual Service PDF Download</h3>
				<div className='row mb-3 mw-'>
					<div className='col'>
						<input
							className='form-control'
							type='text'
							name='certHolder'
							value={inputs.certHolder}
							onChange={handleChange}
							placeholder='Certificate Holder'
						/>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col'>
						<input
							className='form-control'
							type='text'
							name='insurePerson'
							value={inputs.insurePerson}
							onChange={handleChange}
							placeholder='Insured Person'
						/>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col'>
						<input
							className='form-control'
							type='text'
							name='sex'
							value={inputs.sex}
							onChange={handleChange}
							placeholder='Sex'
						/>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col'>
						<input
							className='form-control'
							type='text'
							name='nationality'
							value={inputs.nationality}
							onChange={handleChange}
							placeholder='Nationality'
						/>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col'>
						<input
							className='form-control'
							type='text'
							name='period'
							value={inputs.period}
							onChange={handleChange}
							placeholder='Period'
						/>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col'>
						<input
							className='form-control'
							type='text'
							name='compensationAmount'
							value={inputs.compensationAmount}
							onChange={handleChange}
							placeholder='Compensation Amount'
						/>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col'>
						<input
							className='form-control'
							type='text'
							name='premium'
							value={inputs.premium}
							onChange={handleChange}
							placeholder='Premium'
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col'>
						<button
							type='submit'
							className='btn btn-primary'>
							Submit
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}

export default App
